@import "react-quill/dist/quill.snow.css";
@import "react-quill/dist/quill.core.css";

body {
  margin: 0;
  font-family: RegularTTU sans-serif;
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}



.ql-editor img {
  padding-block: 2rem;
  object-fit: contain;
}

.ql-editor li::before {
  padding-left: 1rem;
  padding-right: 1rem;
}




.result-div .ql-editor {
  padding: 0px;
  font-weight: 400;
}

* .ql-editor {
  font-weight: 400;
}


.max-w {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100% !important;
}

.root-div {
  //max-width: 1200px;
  width: 100%;

}

.base-layout {
  display: flex;
  flex-direction: column; /* Stack header and body vertically */
  min-height: 100vh; /* Ensure the layout takes up at least the full height of the viewport */
}

.base-layout-header{
  width: 100%;
  display: flex; /* Make the header a flex container */
  flex-direction: column; /* Stack items vertically */
  align-items: flex-start; /* Align items to the start of the flex container (left side in a row, top in a column) */
  padding-block: 12px;
}

.base-layout-body{
  flex: 1;
}

.layout-boundary{
  max-width: 1200px;
  padding-bottom: 12px;
  margin-right: auto;
  margin-left: auto;
}

.page-title{
  line-height: 3.5rem;
  font-size: 3rem;
  font-weight: bolder;
  font-family: ThickTTU sans-serif;
  color: #342b60;
}

@media (max-width: 1200px) {
  .layout-boundary{
    padding-inline: 12px;
  }


}


.home-page-main {
  margin: 0px;
  //margin-top: -12px !important;
  width: 100vw;
  padding: 0px;
}

.ttu-card{
  background: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}




main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  line-height: 2.25rem;
  /*margin:2rem;*/
  /*max-width: 1200px;*/

}


html {
  position: relative;
  min-height: 100%;
  font-size: 16px;
  line-height: 2.25rem;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

body {
  //margin-bottom: 60px;
}

.header-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

.header-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.responsive-header {
  display: flex;
  justify-content: space-between;
}

@media(max-height: 992px){
  .responsive-header{
    height: 60px;
  }
}

.hamburger {
  width: 25px;
  height: 25px;
}

.some-gray{
  color: slategray;
}

.hamburger-button {
  background-color: transparent;
  border: 0.5px solid slategray;
  border-radius: 3px;
}

.transition-class {
  transition: max-height 0.2s ease-out;
  overflow: hidden;
}

.collapsed {
  max-height: 0;
}

.expanded {
  max-height: 500px; /* Adjust this value to suit your needs */
}

.top-gradient {
  background: linear-gradient(-90deg, #342b60, #60c3d5);
  width: 100vw;
  max-width: 100vw;
  overflow: hidden;
}



.top-text, .dropdown-menu-custom {
  color: white;
  font-family: ThickTTU sans-serif;
  font-size: 1.125rem;
  font-weight: 100;
}


.top-text:focus {
  color: white;
}

.top-text-active {
  color: #e4067e;
}

.top-text a:hover{
  color: #e4067e !important;
}

.navbar-placement {
  flex-grow: 0;
}


.branding {
  width: 125px;
  overflow: hidden;
  font-weight: 200;
}

@media(max-width: 992px){
  .branding {
    width: 70px;
    overflow: hidden;
  }
}

.result-div {
  border: none !important;
  max-width: 1500px;
  margin-right: auto;
  margin-left: auto;
  font-weight: 500;
  font-family: RegularTTU sans-serif;
}


.result-div.ql-container.ql-snow {
  border: none !important;
  min-height: 0;
}

.language-switcher {
  margin-top: 3rem;
  color: white;
  font-family: ThickTTU sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  text-decoration: none;
}

//
//a:hover {
//  color: #e4067e;
//}

.language-switcher.selected {
  color: #e4067e;
}

.under-language {
  margin-top: 5px;
}

img.content_image {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 16/9;
}


.header-date {
  font-size: 1.25rem;
  color: #e4067e;
  font-weight: 700;
  font-family: ThickTTU sans-serif;
  margin-top: 1.5rem;
}

.content-bg {
  background-color: white;
}

.page_title {
  color: #e4067e;
  font-family: ThickTTU sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 2.0rem;

}

.btn-ttu-pink {
  background-color: #e4067e;
  color: white;
}

.btn-ttu-purple {
  background-color: #342b60;
  color: white;
}

.header-purple {
  color: #342b60;
  font-weight: 400;
  font-family: ThickTTU sans-serif;
}

.topic-area-list {
  color: gray;
  font-family: ThickTTU sans-serif;
  font-weight: 400;
  font-size: 12px;
}

.filter-box {
  border-left: #342b60 1px solid;
}

@media(max-width: 767px) {
  .filter-box {
    border: none;
  }
}

ul li {
  list-style-type: none;
}

.clickable-pointer {
  cursor: pointer;
  pointer-events: auto;
}



.image-wrapper {
  overflow: hidden; /* Hide parts of the image that overflow the container */
  position: relative; /* Establish a positioning context for the image */
  width: 100%; /* Container width - can be set to a specific value if needed */
  padding-top: 56.25%; /* 16:9 Aspect Ratio (9 / 16 = 0.5625) */
}

.responsive-image {
  position: absolute; /* Position relative to the image-wrapper */
  top: 50%; /* Align the center of the image with the center of the container */
  left: 50%;
  transform: translate(-50%, -50%); /* Center the image */
  min-width: 100%; /* Ensure the image covers the full width of the container */
  min-height: 100%; /* Ensure the image covers the full height of the container */
  width: auto; /* Maintain the original aspect ratio of the image */
  height: auto; /* Maintain the original aspect ratio of the image */
}

.filter_box {
  overflow: hidden;
  padding-inline: 1rem;
  padding-top: 0px;
  min-width: 200px;
  border-radius: 8px;
  border-left: 5px solid transparent; /* Set the width and color of the left border */
  border-image: linear-gradient(360deg, #342b60, #60c3d5);
  border-image-slice: 1;
  //background-color: #F1EEEE;
}

@media (max-width: 900px) {
  .filter-box {
    padding-top: 1rem;
  }
}

.link-no-underline {
  text-decoration: none;
  color: #342b60;
  font-family: ThickTTU sans-serif;
  font-weight: 400;
}

.icon-wrapper{
  max-width: 50px;
  cursor: pointer !important;
  color: #e4067e;
}

.icon-wrapper-sm{
  max-width: 30px;
  cursor: pointer;
  color: #e4067e;}

.icon-wrapper-lg{
  max-width: 75px;
  cursor: pointer;
}

.icon{
  max-width: 25px;
}

.purple-on-hover:hover{
  color: #342b60;
}

.link-no-underline-selected {
  text-decoration: none;
  color: #e4067e;
  font-family: ThickTTU sans-serif;
  font-weight: 400;
  cursor: pointer;
}

.link-no-underline:hover {
  color: #e4067e;
  cursor: pointer;

}

.zoom-img-container {
  width: 100%; /* Container width */
  overflow: hidden; /* Hide overflow when image is zoomed */
  position: relative; /* Establish a positioning context */
  padding-top: 56.25%; /* 16:9 Aspect Ratio (9/16 = 0.5625) */
}

/* Ensure the image covers the container, maintaining aspect ratio */
.thumbnail {
  position: absolute; /* Position relative to .zoom-img-container */
  top: 0;
  left: 0;
  width: 100%; /* Make the image stretch to cover the width of the container */
  height: 100%; /* Make the image stretch to cover the height of the container */
  object-fit: cover; /* Cover the container without stretching the image */
  transition: transform 0.5s ease; /* Smooth transition for zoom effect */
}

/* Zoom effect on hover */
.thumbnail:hover {
  transform: scale(1.1); /* Scale the image up to create a zoom effect */
}

//.zoom-img-container {
//  width: 100%; /* Adjust the width as needed */
//  overflow: hidden; /* Hide overflow */
//  position: relative; /* Establish a positioning context */
//}
//
//.zoom-img-container::before {
//  content: '';
//  display: block;
//  padding-top: 56.25%; /* 16:9 Aspect Ratio */
//}
//
//.thumbnail {
//  position: absolute;
//  top: 0;
//  left: 0;
//  width: 100%;
//  height: 100%;
//  object-fit: cover; /* Ensures the image covers the area, cutting off excess */
//  transition: transform 0.5s ease; /* Smooth transition for zoom effect */
//}
//
//.thumbnail:hover {
//  transform: scale(1.2); /* Adjust scale value to control the zoom effect */
//}
.img-container {


}

//.thumbnail {
//  width: 100%; /* Adjust the width as needed */
//  aspect-ratio: 16/9;
//  object-fit: cover;
//}


.dropdown-icon {
  color: #e4067e;
  font-size: 1rem; /* Adjust as needed */
}


.align-right {
  text-align: right;
}

.text-area {
  min-height: 200px;
}

.filter_header {
  font-family: ThickTTU sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// .rotate {


//   writing-mode: vertical-rl;
//   text-orientation: mixed;
//   -webkit-text-o

// }

.contact-person {
  max-width: 450px;
}

.contact-hr {
  height: 5px;
  width: 100%;
  background-color: #e4067e;
}

.contact-person-data {
  white-space: pre-line;
  line-height: 1.25rem;
  color: slategray;
  min-height: 200px;
  max-width: 450px;
}


.contact-person-editor {
  max-width: 450px;
}

.child_list_element {
  padding-left: 1.2rem;
}

.header-pink {
  color: #e4067e !important;
  font-family: ThickTTU sans-serif;
  font-weight: 500;
}

.all-caps{
  text-transform: uppercase;
}

.toggle-category{
  width: 100%;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.05);
  //border-radius: 1rem;
  //padding-bottom: 1rem;
  // border-top: 1px solid #e4067e;
}

.card_custom_form {
  position: relative;
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.2);
  /*padding: 32px;*/
  border-radius: 0px;
  overflow: hidden;
  min-height: 200px;
  min-width: 500px;
  max-width: 70vw;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.git-popup {
  position: relative;
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.2);
  /*padding: 32px;*/
  border-radius: 0px;
  overflow: hidden;
  min-width: 500px;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}



.text-purple-main {
    color: #342b60;
  font-weight: 500;
  font-family: ThickTTU sans-serif;
  font-size: 1.2rem;
}

.text-pink-main {
  color: #e4067e;
  font-weight: 900;
  font-size: 1.2rem;
}

.adm-title {
  font-weight: 900;
}

.admin-menu {
  border-top: #342b60 4px solid
}

b {
  font-family: ThickTTU sans-serif;
  font-weight: 500;
}



.collapse-card{
  //border-radius: 1rem;

  //padding-bottom: 0.5rem;
}

.gray-bg{
  background-color: #f6f6f8;
}

.bg-fill{
  height: 100%;
  margin: 0;
  padding: 0;
}

ul{
  margin-bottom: 0;
}





/* .sticky-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
} */

.ql-snow .ql-editor a {
  text-decoration: none;
  color: #e4067e;
}

.ql-snow .ql-editor a:hover {
  color: #a8045c;
}


.footer {
  font-family: RegularTTU sans-serif;
  font-weight: 100;
  //margin-top: 5rem;
  width: 100vw;
  max-width: 100vw;
  overflow: hidden;
  min-height: 6rem;
  position: relative;
  //margin-bottom: -60px;
  z-index: 5;
}


/*
Quill
*/

.ql-editor * {
  line-height: 2.25rem;
  font-size: 16px;
  font-family: RegularTTU sans-serif;
}

.ql-container {
  font-size: inherit;
}

.quill-editor iframe {
  pointer-events: none;
}


.ql-editor strong {
  font-weight: 500;
  font-family: ThickTTU sans-serif;
}


.ql-editor .ql-video {
  display: block;
  width: 40rem;
  font-family: RegularTTU sans-serif;
  overflow: auto;
  min-height: 20rem;
  margin-bottom: auto;
}

/*
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="8px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="8px"]::before {
  content: '8';
  font-size: 8px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: '16';
  font-size: 16px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: '12';
  font-size: 12px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: '10';
  font-size: 10px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: '14';
  font-size: 14px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: '18';
  font-size: 18px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: '20';
  font-size: 20px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
  content: '32';
  font-size: 32px;
} */


.btn-primary-pink {
  font-size: 1.125rem;
  font-weight: 900;
  text-transform: uppercase;
  color: #fff;
  border: 4px solid #e4067e;
  line-height: 1.25rem;
  background-color: #e4067e;
  transition: all .3s ease;
  display: inline-block;
  text-decoration: none;
  padding: calc(1.1rem - 4px) calc(2.3125rem - 4px) calc(1.4rem - 4px);
  border-radius: 60px;
}

.container-fluid {
  max-width: 1200px;
  padding: 0 12px; // Default padding of 12px

  @media (min-width: 1213px) {
    padding-inline: 0; // No padding for screens wider than 1212px
  }
}

.weight-fix {
  font-weight: 600 !important;
}

.close-button {
  font-weight: 600;
  color: #342b60;
  width: 40px;
  background-color: transparent;
  border: none;
}

.git-style-card {
  border: 1px solid black;
}

.git-style-card :hover {
  cursor: pointer;
}


.popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 9999;
}

.popup-content {
  min-width: 30vw;
  min-height: 20vw;
  background-color: white;
}

.code-block {
  font-family: 'Courier New', Courier, monospace; /* Monospace font for code-like appearance */
  background-color: #f9f9f9; /* Light grey background */
  border: 1px solid #ddd; /* Light grey border */
  border-radius: 4px; /* Rounded corners */
  padding: 10px; /* Padding inside the box */
  overflow-x: auto; /* Horizontal scroll for long lines */
  white-space: pre-wrap; /* Keeps the format of whitespaces and new lines */
  word-break: break-all; /* Ensures long words do not overflow */
  line-height: 1.5; /* Adjust line height for readability */
  color: #333; /* Darker text color for contrast */
}

.home-page-carousel-img {
  min-width: 100%;
  aspect-ratio: 16/9;
  object-fit: contain;
  position: fixed;
  /*z-index: 100;*/
}

.image-carousel {
  overflow: hidden;
  /*position: relative;*/
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.home-page-img-div {
  /*position: flex;*/
  /*width: 100%;*/
  /*display: flex;*/
  /*margin-left: -110px;*/
  /*margin-top: -31px;*/
  background-image: url("./assets/ttu-hall.jpeg");
  background-position: 50% 39px;
  background-size: cover;
  background-repeat: no-repeat;
  /*z-index: 0;*/
  background-attachment: fixed;
  left: 1548px;
  /*z-index: 100;*/
}

.carousel-container {
  margin-left: -100px;
  margin-right: -100px;
}

.create-partner-image {
  padding: 3rem;
  min-width: 600px;
}

body.safari .banner-container {
  /* Your specific styles for Safari */
  background-attachment: scroll; /* example */
}

.banner-preview {
  background-color: rgba(0, 0, 0, 0.6);
  box-sizing: border-box;
  background-blend-mode: overlay;
  //border-radius: 1.3rem;
  background-repeat: no-repeat;
  aspect-ratio: 16/9 !important;
}

.nav-item a:hover{
  color: #e4067e !important;
}

.banner-card {
  background-color: #dad9d9;
  //max-width: 900px;
  padding-top: 0.45rem;
  display: flex;
  max-height: 50vh;
  max-width: 75vh;
  align-content: center;
  //padding-right: 2rem;
  justify-content: center;
  padding-bottom: 0.45rem;
  padding-inline: 1rem;
  //border-radius: 2rem;
  margin-top: 2rem;
}

.b-radius-0{
  border-radius: 0;
}


.banner-container {
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.6);
  background-repeat: no-repeat;
  transition: background-image 1s ease-in-out;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  //margin-top: -5vw;
  margin-left: -50vw;
  margin-right: -50vw;
  overflow: hidden;
  display: flex;
  min-height: 95vh;
  //height: 40vw;
  //min-height: 350px;
  //height: 1200px;
  //height: 25%;
  //aspect-ratio: 16/9;
  justify-content: center;
  background-blend-mode: overlay;
  align-items: center;
  will-change: transform;
}

@media (max-width: 900px) {
  .banner-container{
    aspect-ratio: 16/9;
    min-height: 0;
  }
}

.carousel-inner {
  transition: initial 500ms ease-in-out;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animated-text {
  animation: slideIn 1s ease;
}


.home-page-title {
  font-size: 3rem;
  line-height: 3.5rem;
  font-family: ThickTTU sans-serif;
  text-shadow: #1a1d20;
  font-weight: 500;
}

@media (max-width: 767px) {
  .home-page-title{
    font-size: 2rem;
  }
  .home-page-subtitle{
    font-size: 1rem;
  }
}

.underline-last-line {
  position: relative;
  display: inline-block; /* Ensures the ::after is relative to the text */
}

.underline-last-line::after {
  content: '';
  position: absolute;
  bottom: 0; /* Aligns the line at the bottom of the text */
  left: 50%; /* Starts from the middle */
  transform: translateX(-50%); /* Centers the line */
  width: 300px; /* Fixed width of the underline */
  height: 1px; /* Thickness of the underline */
  background-color: #e4067e; /* Makes the line the same color as the text */
}


.home-page-subtitle {
  font-weight: 500;
}

.home-page-content-container {
  margin-top: 2rem;
  margin: 8px;
}

.home-page-li {
  list-style-type: circle;
}

//.home-page-link-container {
//  display: flex;
//  //align-items: center;
//  justify-content: center;
//}

.nav-images{
  display:flex;
  flex-direction: column;
  min-height: 200px;
  margin-right: 5px;
}

@media(max-width: 767px){
  .nav-images{
    min-height: 200px;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    margin-right: 0px;
  }
}


/* Base icon style, including transition */
.icon {
  transition: transform 0.3s ease; /* Smooth transition for the rotation */
  pointer-events: none; /* Keeping your rule to disable pointer events */
}

/* Expanded state - no additional rotation */
.expanded-collapse-arrow {
  transform: rotate(0deg); /* Ensures the arrow points down when expanded */
}

/* Collapsed state - rotate arrow */
.collapse-arrow {
  transform: rotate(-90deg); /* Arrow points left when collapsed */
}

.image-link {
  font-family: ThickTTU sans-serif;
  font-weight: 900;
  background-blend-mode: overlay;
  background-color: rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-width: 150px;
  min-height: 150px;

  aspect-ratio: 1/1;
  max-width: 300px;
  max-height: 300px;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s ease;
  cursor: pointer;
  align-content: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .image-link{
    min-width: 150px;
    min-height: 150px;

  }
}

.image-link:hover {
  background-color: rgba(0, 0, 0, 0.6);

}


.image-link-label {
  position: absolute;
  bottom: 0;
  color: white;
  margin: 1rem;
  font-weight: 500;
  font-size: 1.125rem;
  transition: border-bottom 0.3s ease; /* Add this for smooth transition */
  border-bottom: 3px solid transparent;
}

.image-link:hover .image-link-label {
  border-bottom: 3px solid #e4067e; /* Underline appears on hover */
}

.link-arrow {
  font-family: RegularTTU sans-serif;
  font-weight: normal;
  font-size: 1.125rem;
  color: #e4067e;
}

.partner-image {
  margin: 1.5rem;
  max-width: 300px;
}


@media(max-width: 767px){
  .partner-image{
    width: 200px;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}

.admin-header-title {
  font-weight: 900;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


@font-face {
  font-family: ThickTTU sans-serif;
  src: url("./assets/fonts/black/ProximaNova-Black.woff2") format('woff2'),
  url("./assets/fonts/black/ProximaNova-Black.woff") format('woff'),
  url("./assets/fonts/black/ProximaNova-Black.ttf") format('truetype');
}

@font-face {
  font-family: RegularTTU sans-serif;
  src: url("./assets/fonts/regular/ProximaNova-Regular.woff2") format('woff2'),
  url("./assets/fonts/regular/ProximaNova-Regular.woff") format('woff'),
  url("./assets/fonts/regular/ProximaNova-Regular.ttf") format('truetype');
}


.menu-enter {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}

.menu-enter-active {
  opacity: 1;
  max-height: 500px; // or set this to the max required height
  transition: opacity 300ms, max-height 300ms;
}

.menu-exit {
  opacity: 1;
  max-height: 500px;
}

.menu-exit-active {
  opacity: 0;
  max-height: 0 !important;
  transition: opacity 300ms, max-height 300ms;
}

.-resize-align-left {
  display: inline;
  margin: 0px 1em 1em 0px;
  float: left;
}


.-resize-align-center {
  display: block;
  text-align: center;
  margin: auto;
}

.-resize-align-right {
  display: inline;
  margin: 0px 0px 1em 1em;
  float: right;
}

.spinner {
  position: fixed; /* Fixes the spinner to a position relative to the viewport */
  top: 0; /* Aligns the top edge of the spinner with the top of the viewport */
  left: 0; /* Aligns the left edge of the spinner with the left of the viewport */
  width: 100%; /* Ensures the spinner stretches across the full width of the viewport */
  height: 100%; /* Ensures the spinner stretches across the full height of the viewport */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* A high z-index value to place the spinner above other elements */

  /* Semi-transparent black background to darken the area behind the spinner */
  background-color: rgba(0, 0, 0, 0.0);

  /* Apply a blur effect to the background behind the spinner */
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

a {
  color: #e4067e
}

a:hover {
  color: #342b60 !important
}

.dot1, .dot2, .dot3 {
  width: 15px;
  height: 15px;
  border: double;
  border-color: white;
  border-radius: 50%;
  margin: 10px;
}

.dot1 {
  animation: jump765 1.6s -0.32s linear infinite;
  background: #e4067e;
}

.dot2 {
  animation: jump765 1.6s -0.16s linear infinite;
  background: #e4067e;
}

.dot3 {
  animation: jump765 1.6s linear infinite;
  background: #e4067e;
}

@keyframes jump765 {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(2.0);
    transform: scale(2.0);
  }
}

.pagination-element {
  //font-size: 1.125rem;
  font-weight: 500;
  font-family: ThickTTU sans-serif;
  text-decoration: none;
  margin-inline: 1rem
}

.active-page-li {
  color: #342b60;
  text-decoration: none;
}

.pagination-navigation {
  padding-inline: 12px;
  margin-bottom: 0px;
  display: flex;
  width: 50%;
  padding: 0.5rem;
  border-radius: 8px;
}

@media(max-width: 991px) {
  .pagination-navigation {
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.news-grid {
  min-height: 800px;
}


.pagination-holder {
  height: 50%;
  display: flex;
  align-items: flex-end;
}

.oops {
  font-weight: 500;
  font-family: ThickTTU sans-serif;
  font-size: 7rem;
  color: #e4067e;
}

.loader {
  display: block;
  --height-of-loader: 4px;
  --loader-color: #e4067e;
  width: 100%;
  height: var(--height-of-loader);
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.2);
  position: relative;
}

.loader::before {
  content: "";
  position: absolute;
  background: var(--loader-color);
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  border-radius: 30px;
  animation: moving 1s ease-in-out infinite;;
}

@keyframes moving {
  50% {
    width: 100%;
  }

  100% {
    width: 0;
    right: 0;
    left: unset;
  }
}


.project-card {
  //width: 300px;
  min-height: 250px;
  border-left: 5px solid linear-gradient(-180deg, #342b60, #60c3d5);
}

.gradient-border {
  border-left: 5px solid transparent; /* Set the width and color of the left border */
  border-image: linear-gradient(360deg, #342b60, #60c3d5); /* Define the gradient */
  border-image-slice: 1; /* Slice the gradient to cover the entire border */
  //border-radius: 10px; /* Set the border radius */
  padding-left: 25px; /* Add some padding to separate content from the border */
  background-color: #ffffff; /* Set the background color (optional) */
}

.text-small-gray {
  font-weight: 100;
  height: 1px;
  line-height: 15px;
  color: #342b6b;
  font-size: 0.95rem;
}

.border-technology{
  border-bottom: 1px solid #342b6b;
 border-left: 1px solid #342b6b;
;
}

.proj-key{
  color: gray;
  font-family: RegularTTU sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.proj-val{
  font-weight: 200;
  color: #e4067e;
  font-family: ThickTTU sans-serif;
}

.notification {
  padding: 0.3rem;
  display: flex;
  flex-direction: column;
  isolation: isolate;
  position: relative;
  width: 18rem;
  min-height: 20rem;
  background: rgba(255, 255, 255, 0);
  //border-radius: 1rem;
  overflow: hidden;
  font-family: ThickTTU sans-serif;
  font-size: 16px;
  --gradient: linear-gradient(to bottom, #342b60, #60c3d5);
  --color: #32a6ff
}

.border-danger{
  border: 0.5px solid red;
}

.notification:before {
  position: absolute;
  content: "";
  inset: 0.0625rem;
  //border-radius: 0.9375rem;
  background: rgba(0, 0, 0, 0.05);
  z-index: 2
}

.notification:after {
  position: absolute;
  content: "";
  width: 0.25rem;
  inset: 0.65rem auto 0.65rem 0.5rem;
  border-radius: 0.125rem;
  background: var(--gradient);
  transition: transform 300ms ease;
  z-index: 4;
}

.notification:hover:after {
  transform: translateX(0.15rem)
}

.notititle {
  color: #342b60;
  font-weight: 400;
  font-family: ThickTTU sans-serif;
  padding: 0.65rem 0.25rem 0.4rem 1.25rem;
  font-size: 1.75rem;
  transition: transform 300ms ease;
  z-index: 5;
}

.notification:hover .notititle {
  transform: translateX(0.15rem)
}

.notibody {
  color: #868686;
  margin-top: auto;
  padding: 0 1.25rem;
  align-items: end;
  transition: transform 300ms ease;
  z-index: 5;
}

.notification:hover .notibody {
  transform: translateX(0.25rem)
}

.notiglow,
.notiborderglow {
  position: absolute;
  width: 20rem;
  height: 20rem;
  transform: translate(-50%, -50%);
  background: radial-gradient(circle closest-side at center, white, transparent);
  opacity: 0;
  transition: opacity 300ms ease;
}

.notiglow {
  z-index: 3;
}

.notiborderglow {
  z-index: 1;
}

.notification:hover .notiglow {
  opacity: 0.1
}

.notification:hover .notiborderglow {
  opacity: 0.1
}

.note {
  color: var(--color);
  position: fixed;
  top: 80%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 0.9rem;
  width: 75%;
}

.no-br{
  border-radius: 0px;
}

.post-card{
  padding: 1rem;
  border-left: #342b60 solid 3px;
  border-radius: 1rem;
background-color: #f9f9f9;
}

.oss-card{
  min-height: 400px;
}

.to-bottom{
  margin-top: auto !important;
  justify-self: flex-end !important;
  align-self: flex-end !important;
}

.lock{
  margin-right: 10px;
  height: 25px;
  width: 25px;
}


p{
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: RegularTTU sans-serif;
  color: #25262c;
}


h1{
  line-height: 2.25rem;
  font-size: 1.75rem;
  font-weight: bold;
  font-family: ThickTTU sans-serif;
  margin-bottom: 1rem;
  color: #342b60;
}

h2{
  line-height: 2rem;
  font-size: 1.625rem;
  font-weight: bolder;
  font-family: ThickTTU sans-serif;
  margin-bottom: 1rem;
  color: #342b60;
}

h3{
  line-height: 1.75rem;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: ThickTTU sans-serif;
  margin-bottom: 1rem;
  color: #342b60;
}

h4{
  line-height: 1.5rem;
  font-size: 1.25rem;
  font-weight: bolder;
  font-family: ThickTTU sans-serif;
  margin-bottom: 1rem;
  color: #342b60;
}


.ql-snow .ql-editor h1 {
  line-height: 2.25rem;
  font-size: 1.75rem;
  font-weight: bold;
  font-family: ThickTTU sans-serif;
  margin-bottom: 1rem;
  color: #342b60;
}

.ql-snow .ql-editor p{
  font-size: 1rem;
  font-family: RegularTTU sans-serif;
}


.ql-snow .ql-editor h2 {
  line-height: 2rem;
  font-size: 1.625rem;
  font-weight: bolder;
  font-family: ThickTTU sans-serif;
  margin-bottom: 1rem;
  color: #342b60;
}

.ql-snow .ql-editor h3 {
  line-height: 1.75rem;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: ThickTTU sans-serif;
  margin-bottom: 1rem;
  color: #342b60;
}

@media (max-width: 767px) {
  p{
    font-size: 1rem;
    font-family: RegularTTU sans-serif;
    color: #25262c;
  }

  .page-title{
    line-height: 2.75rem;
    font-size: 2.35rem;
    font-weight: bolder;
    font-family: ThickTTU sans-serif;
    margin-bottom: 1rem;
    color: #342b60;
  }
  h1{
    line-height: 2rem;
    font-size: 1.625rem;
    font-weight: bold;
    font-family: ThickTTU sans-serif;
    margin-bottom: 1rem;
    color: #342b60;
  }

  h2{
    line-height: 2rem;
    font-size: 1.5rem;
    font-weight: bolder;
    font-family: ThickTTU sans-serif;
    margin-bottom: 1rem;
    color: #342b60;
  }

  h3{
    line-height: 1.75rem;
    font-size: 1.375rem;
    font-weight: bold;
    font-family: ThickTTU sans-serif;
    margin-bottom: 1rem;
    color: #342b60;
  }

  h4{
    line-height: 1.5rem;
    font-size: 1.175rem;
    font-weight: bolder;
    font-family: ThickTTU sans-serif;
    margin-bottom: 1rem;
    color: #342b60;
  }


  .ql-snow .ql-editor h1 {
    line-height: 2rem;
    font-size: 1.625rem;
    font-weight: bold;
    font-family: ThickTTU sans-serif;
    margin-bottom: 1rem;
    color: #342b60;
  }

  .ql-snow .ql-editor p{
    font-size: 1rem;
    font-family: RegularTTU sans-serif;
  }


  .ql-snow .ql-editor h2 {
    line-height: 2rem;
    font-size: 1.5rem;
    font-weight: bolder;
    font-family: ThickTTU sans-serif;
    margin-bottom: 1rem;
    color: #342b60;
  }

  .ql-snow .ql-editor h3 {
    line-height: 1.75rem;
    font-size: 1.375rem;
    font-weight: bold;
    font-family: ThickTTU sans-serif;
    margin-bottom: 1rem;
    color: #342b60;
  }
}


.post-category{
  padding: 1rem 1rem 1rem 1.5rem;
  margin: 0;
  border-left: 5px solid #cccad7;
}

.post-category:hover{
  padding: 1rem 1rem 1rem 1.5rem;
  margin: 0;
  color:  #e4067e;
  border-left: 5px solid  #e4067e;
}

.post-category-active{
  padding: 1rem 1rem 1rem 1.5rem;
  margin: 0;
  color:  #e4067e;
  border-left: 5px solid  #e4067e;
}


.button-loader{
  margin: -20px;
  height: 60px;
  width: 100px;
}

.arrow-left{
  margin-left: -55px;
  margin-top: -15px;
}

